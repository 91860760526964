.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
body{
  background: #4F4F4F;
  font-family: 'Open Sans',sans-serif;
}
.home{
  background-color:#F3F3F3;
  width: 20%;
}
.home .list-group-item {
  margin: 10px 0px;
  border: 1px solid #bbb;
  border-radius: 8px;
  font-size: 13px;
  padding: 7px;
  border-width: 1px !important;
  background-color:#F3F3F3;
  color: #2f6b77;
  font-weight: bold;
}
.mainarea
{
  padding:30px 10px;
  width:60%
}
.mainarea h3, .dtheading
{
  margin:30px 0px 0px;
  border: 1px solid #eaeaea;
  padding: 5px 15px;
  background-color: #1a4d57;
  font-size: 20px;
  border-top-left-radius: 10px; /* Rounded top-left corner */
    border-top-right-radius: 10px; /* Rounded top-right corner */
    border-bottom-left-radius: 0; /* Square bottom-left corner */
    border-bottom-right-radius: 0; /* Square bottom-right corner */
  color: #eaeaea;
  width: max-content;
}
.logoarea
{
  text-align: center;
}
.topbanner{
  width: 100%;
}
.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  -color: #4158D0;
  background: #B993D6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #8CA6DB, #B993D6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #8CA6DB, #B993D6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 0.3rem;
  width: 100%;
  height: 400px;
  text-align: center;
}
.jumbotron .container
{
  color: #fff;
  padding: 15px 25px;
}
.jumbotron .container p
{
  font-size: 20px;
}
.container{
  width: 1100px;
}
.link2
{
color: #2f6b77;
font-family: Tahoma;
text-decoration:none;
}
.jmb{
    flex: 0 0 auto;
    width: 25%;
}
h2{
  margin-top: 10px;
  margin-bottom: 30px;
}
.states
{
  text-transform: capitalize;
  font-size: 12px;
}
label{
  margin-right: 5px;
}
.pgheading
{
color: #2f6b77;
font-weight: bold;
font-family: tahoma;
margin-bottom:30px
}
.searchbox2
{
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
  text-align: center;
  border-radius: 8px;
    box-shadow: 0 79px 32px rgba(72,75,81,.01), 0 20px 20px rgba(72,75,81,.04), 0 5px 11px rgba(72,75,81,.05);
    padding: 15px 20px 13px;
  font-size: 14px;
}
.searchbox2 select
{
  width: 250px
} 
.form-control
{
	display: inline-block;
	font-size: 14px;
}
.searchbox
{
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
  text-align: center;
  margin-top:-60px;
  border-radius: 8px;
    box-shadow: 0 79px 32px rgba(72,75,81,.01), 0 20px 20px rgba(72,75,81,.04), 0 5px 11px rgba(72,75,81,.05);
    padding: 15px 20px 13px;
}
.hed
{
  font-size: 28px;
}
.searchbox1
{
  background-color: #e9ecef;
  padding: 1rem 1rem;
}
table
{
  font-size: 14px;
}
table a
{
  color: #2f6b77;
}


.table-container {
  overflow: hidden;
  position: relative;
}

/* Table styling */
.hometable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #eaeaea;
  table-layout: fixed;
}

.hometable th, .hometable td {
  padding: 5px;
  text-align: left;
}

/* Apply scrolling only to the tbody */
.hometable tbody {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative; /* Positioning for the marquee effect */
  animation: marquee 10s linear infinite;
  overflow-y: auto;
  overflow-x: hidden;
}
table.hometable th {
  background-color: #f1f1f1;
}
/* Keep the thead fixed */
.hometable thead {
  display: table;
  width: 100%;
  font-size: 11px;
  background-color: #eaeaea;
}

.hometable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
}

.main
{
  padding:10px;
  background: #fff;
}
h1 {
  font-size: 1.5rem;
}
.toprow
{
  text-align: center;
  padding: 40px;
}
.btn-bt
{
  background: #41548c;
  color: #fff;
  top: 0;
  left: 0;
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  letter-spacing: 1px;
  box-shadow: -6px 6px 0 #404040;
  text-decoration: underline;
}
.profiletable, .tenders{
  border:1px solid #eaeaea;
  width: 100%;
}
.profiletable tr td, .tenders tr td{
  border:1px solid #eaeaea;
  padding:10px;
}
.filter{ 
  padding: 10px;
}
tr.shortlisted td{
  background-color: lightgreen !important;
}
.btn-primary{
  background: rgb(26, 77, 87);
}
.theading
{
  font-size: 18px;
  margin:20px 0;
  background-color: #1a4d57; 
  padding: 10px;
  color: #fff;;
}
.App-link {
  color: #61dafb;
}
.frmsection
{
  background-color: #F3F3F3;
  margin:10px 5px 50px;
  padding:10px;
}
.frmsection p.heading{
  border: 1px solid #eaeaea;
  padding: 5px;
  background-color: #fff;
  width: 100%;
  margin-top: -30px;
  font-size: 15px;
  font-weight: bold;
}
.signupside
{
  font-size: 10px;
  border: 1px solid #333;
  padding:5px;
}
#menu4 {
  width: 195px;
}
#menu4 ul{
  list-style: none;
  margin: 0px;
  padding: 0px;;
}
#menu4 li a:link, #menu4 li a:visited {
  color: #ffffff;
  display: block;
  FONT-WEIGHT: bold;
  FONT-FAMILY: tahoma;
  FONT-SIZE: 10px;
  background: url(../images/menu4.png);
  padding: 10px 0 0 30px;
}

#menu4 li a {
  Height: 40px;
}
.ctrlbox{
  margin: 5px 0px 30px;
}
ul.ln
{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
ul.ln li a{
  font-family: Tahoma;
  font-size: 11px;
  font-weight: bold;
  COLOR: #2f6b77;
  text-decoration: none;
  PADDING-BOTTOM: 5px;
  height: 30px;
}
footer
{
  background-color: #1a4d57;
    padding: 25px 0px 0px;
    color: #eaeaea;
    font-size: 12px;
}
footer a{
  color: #ebebeb;
}
.fw
{
  width: 100%;
  border: 1px solid #eaeaea;
}
.copyright
{
  background-color: #153d45;
  text-align: center;
  padding:10px;
  font-size: 12px;
}
.searchfilters
{
  padding-top: 20px;
}
.sml
{
  font-size: 16px;
}
.cat{
  padding:20px 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 79px 32px rgba(72,75,81,.01), 0 20px 20px rgba(72,75,81,.04), 0 5px 11px rgba(72,75,81,.05);
  display: flex;
  flex-flow: column wrap;
}
.cat:hover{
  background: #eaeaea;
}
.center
{
  text-align: center;
}
.hm
{
  margin-top: 50px;
}
.cover{
  width: 90%;
  height: 150px;
}
#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border:  solid 4px transparent;
  border-top-color:  #00C8B1 !important;
  border-left-color: #00C8B1 !important;
  border-radius: 50%;
}
.ctrlsearch
{
  background-color: #2f6b77;
  padding: 5px;
  color: #fff;;
}
.ctrlsearch a
{
  color: #fff;;
  FONT-WEIGHT: bold;
  FONT-FAMILY: tahoma;
  FONT-SIZE: 10px;
}
.navbar-brand
{
  FONT-WEIGHT: normal;
  FONT-SIZE: 11px;
  COLOR: #FFFFFF;
  FONT-FAMILY: tahoma;
  TEXT-DECORATION: none;
  text-align: center;
}
.ctrlsearch input.box{
  width: 70%;
}
.ctrlsearch input, .ctrlsearch button{
  height: 25px;
  padding: 2px 5px;
  font-size: 13px;
}

td.col {
  width: 200px;
}
.dropdown-toggle:hover
{
  color: #fff; 
}
.dropdown-toggle, .dropdown-menu
{
  color: #fff;
  font-size: 12px;
}
.active{
  background: rgb(26, 77, 87) !important;
}
.usersidebar
{
  font-size: 14px;
}
.request-details p {
  margin: 10px 0;
}

.replies-section {
  margin-top: 20px;
}

.reply {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.reply-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.reply-body {
  margin-top: 5px;
}

.user-reply {
  border-left: 5px solid #007bff;
}

.company-reply {
  border-left: 5px solid #28a745;
}

.reply-form {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.reply-form label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.reply-form textarea {
  width: 100%;
  height: 100px; /* You can adjust this height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing */
  font-size: 14px;
  font-family: 'Arial', sans-serif; /* Change font-family as needed */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.reply-form textarea:focus {
  border-color: #007bff; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none; /* Remove default outline */
}

.reply-form button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.attachment-link {
  display: inline-block;
  margin-top: 5px;
  color: #007bff; /* Link color */
  text-decoration: none;
}

.attachment-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.form-group {
  margin-bottom: 20px;
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
